import type { ParsedUrlQuery } from 'querystring';
import Cookies from 'js-cookie';
import { parseStringUrlQuery } from '$util/index';

export const getFixterMembershipGarageId = (): string | undefined => {
  return Cookies.get('fixterMembershipGarage');
};

export const getFixterMembershipGarageDomain = (): string | undefined => {
  return Cookies.get('fixterMembershipGarageDomain');
};

/**
 * For cosmetic changes like changing the logo to the garage one
 * when user is on the Garage custom domain.
 *
 * @warn for most of the cases you want to use `getIsMembershipGarageFromBookingTags()`
 * which applies to both Garage Membership flows:
 * - Garage custom domain
 * - Fixter garage directory page
 *
 * @todo better naming, eg. `getIsGarageMembershipCustomDomain()`
 */
export const getIsGarageMembership = (): boolean => !!getFixterMembershipGarageId();

export const getIsGarageMembershipDomain = (): boolean => !!getFixterMembershipGarageDomain();

export const getIsMembershipGarageFromUrl = (urlQuery: ParsedUrlQuery): boolean => {
  const { isGarageMembership: isGarageMember } = parseStringUrlQuery(urlQuery);
  return isGarageMember === 'true';
};

/**
 * Check if we're on any of the Garage Membership flows:
 * - Garage custom domain
 * - Fixter garage directory page
 *
 * * @todo better naming, eg. `getIsGarageMembershipBooking()`
 */
export const getIsMembershipGarageFromBookingTags = (tags?: string): boolean => {
  let isGarageMembership = false;
  try {
    const jsonTags = JSON.parse(tags || '');
    if (Array.isArray(jsonTags)) {
      isGarageMembership = jsonTags.some((tag) => tag === 'garageMembership');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Booking tags could not be parsed');
  }

  return isGarageMembership;
};
