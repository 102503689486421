import config from 'config';
import { CardStatus, CollectionDeliveryTypes } from '$util/types';
import type { SchedulePageCardsStatus } from './SchedulePageCardsStatus';

export const determinePageStateCurrent = (
  collectionAddressId?: number,
  collectionDateTimeLocal?: string,
  deliveryDateTimeLocal?: string,
  garageId?: string,
  collectionDeliveryOption?: CollectionDeliveryTypes | null,
  isGarageMembershipBooking = false
): SchedulePageCardsStatus => {
  if (!collectionDeliveryOption || collectionDeliveryOption === CollectionDeliveryTypes.UNKNOWN) {
    return {
      selectTransportMethod: CardStatus.ACTIVE,
      address: CardStatus.INACTIVE,
      outOfCoverage: CardStatus.SKIP,
      collection: CardStatus.INACTIVE,
      delivery: CardStatus.INACTIVE,
      selectGarage: CardStatus.SKIP,
      selectGarageDropoff: CardStatus.SKIP,
    };
  }

  /**
   * @todo remove flag after release
   */
  const isGarageMembershipFlow =
    config.get<boolean>('public.features.garageMembershipCollectBillingAddress') &&
    isGarageMembershipBooking &&
    collectionDeliveryOption === CollectionDeliveryTypes.CUSTOMER;
  if (isGarageMembershipFlow) {
    return {
      selectTransportMethod: CardStatus.COMPLETED,
      selectGarage: CardStatus.COMPLETED,
      selectGarageDropoff: deliveryDateTimeLocal ? CardStatus.COMPLETED : CardStatus.ACTIVE,
      address: CardStatus.SKIP,
      outOfCoverage: CardStatus.SKIP,
      collection: CardStatus.SKIP,
      delivery: CardStatus.SKIP,
    };
  }

  if (!collectionAddressId) {
    return {
      selectTransportMethod: CardStatus.COMPLETED,
      address: CardStatus.ACTIVE,
      outOfCoverage: CardStatus.SKIP,
      collection: CardStatus.INACTIVE,
      delivery: CardStatus.INACTIVE,
      selectGarage: CardStatus.SKIP,
      selectGarageDropoff: CardStatus.SKIP,
    };
  }

  const outOfCoverage = collectionDeliveryOption === CollectionDeliveryTypes.OUT_OF_COVERAGE;
  if (outOfCoverage) {
    return {
      selectTransportMethod: CardStatus.SKIP,
      address: CardStatus.COMPLETED,
      outOfCoverage: CardStatus.ACTIVE,
      collection: CardStatus.SKIP,
      delivery: CardStatus.SKIP,
      selectGarage: CardStatus.SKIP,
      selectGarageDropoff: CardStatus.SKIP,
    };
  }

  if (
    ![
      CollectionDeliveryTypes.FIXTER,
      CollectionDeliveryTypes.GARAGE,
      CollectionDeliveryTypes.CUSTOMER,
    ].includes(collectionDeliveryOption)
  ) {
    return {
      selectTransportMethod: CardStatus.ACTIVE,
      address: CardStatus.COMPLETED,
      outOfCoverage: CardStatus.SKIP,
      collection: CardStatus.INACTIVE,
      delivery: CardStatus.INACTIVE,
      selectGarage: CardStatus.SKIP,
      selectGarageDropoff: CardStatus.SKIP,
    };
  }

  if ([CollectionDeliveryTypes.FIXTER, CollectionDeliveryTypes.GARAGE].includes(collectionDeliveryOption)) {
    if (deliveryDateTimeLocal) {
      return {
        selectTransportMethod: CardStatus.COMPLETED,
        address: CardStatus.COMPLETED,
        outOfCoverage: CardStatus.SKIP,
        collection: CardStatus.COMPLETED,
        delivery: CardStatus.COMPLETED,
        selectGarage: CardStatus.SKIP,
        selectGarageDropoff: CardStatus.SKIP,
      };
    }
    if (collectionDateTimeLocal) {
      return {
        selectTransportMethod: CardStatus.COMPLETED,
        address: CardStatus.COMPLETED,
        outOfCoverage: CardStatus.SKIP,
        collection: CardStatus.COMPLETED,
        delivery: CardStatus.ACTIVE,
        selectGarage: CardStatus.SKIP,
        selectGarageDropoff: CardStatus.SKIP,
      };
    }
    return {
      selectTransportMethod: CardStatus.COMPLETED,
      address: CardStatus.COMPLETED,
      outOfCoverage: CardStatus.SKIP,
      collection: CardStatus.ACTIVE,
      delivery: CardStatus.INACTIVE,
      selectGarage: CardStatus.SKIP,
      selectGarageDropoff: CardStatus.SKIP,
    };
  }

  if (collectionDeliveryOption === CollectionDeliveryTypes.CUSTOMER) {
    if (deliveryDateTimeLocal) {
      return {
        selectTransportMethod: CardStatus.COMPLETED,
        address: CardStatus.COMPLETED,
        outOfCoverage: CardStatus.SKIP,
        collection: CardStatus.SKIP,
        delivery: CardStatus.SKIP,
        selectGarage: CardStatus.COMPLETED,
        selectGarageDropoff: CardStatus.COMPLETED,
      };
    }
    if (collectionDateTimeLocal) {
      return {
        selectTransportMethod: CardStatus.COMPLETED,
        address: CardStatus.COMPLETED,
        outOfCoverage: CardStatus.SKIP,
        collection: CardStatus.SKIP,
        delivery: CardStatus.SKIP,
        selectGarage: CardStatus.COMPLETED,
        selectGarageDropoff: CardStatus.ACTIVE,
      };
    }
    if (garageId) {
      return {
        selectTransportMethod: CardStatus.COMPLETED,
        address: CardStatus.COMPLETED,
        outOfCoverage: CardStatus.SKIP,
        collection: CardStatus.SKIP,
        delivery: CardStatus.SKIP,
        selectGarage: CardStatus.COMPLETED,
        selectGarageDropoff: CardStatus.ACTIVE,
      };
    }
    return {
      selectTransportMethod: CardStatus.COMPLETED,
      address: CardStatus.COMPLETED,
      outOfCoverage: CardStatus.SKIP,
      collection: CardStatus.SKIP,
      delivery: CardStatus.SKIP,
      selectGarage: CardStatus.ACTIVE,
      selectGarageDropoff: CardStatus.INACTIVE,
    };
  }

  return {
    selectTransportMethod: CardStatus.ACTIVE,
    address: CardStatus.INACTIVE,
    outOfCoverage: CardStatus.INACTIVE,
    collection: CardStatus.INACTIVE,
    delivery: CardStatus.INACTIVE,
    selectGarage: CardStatus.SKIP,
    selectGarageDropoff: CardStatus.SKIP,
  };
};
