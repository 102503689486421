import type { FieldFunctionOptions } from '@apollo/client';
import { isFeatureSchedulePageOptionalCnDActive } from '$util/features';
import type { CollectionDeliveryTypes } from '$util/types';
import { getIsMembershipGarageFromBookingTags } from '@/util/garageMembership';
import type { QueryGetBooking, QueryGetBookingVariables } from './queryGetBooking';
import { QUERY_GET_BOOKING } from './queryGetBooking';
import type { SchedulePageCardsStatus } from './SchedulePageCardsStatus';
import { determinePageStateCurrent } from './determinePageStateCurrent';
import { determinePageStateDefault } from './determinePageStateDefault';

export type ReadSchedulePageCardsStatusVariables = QueryGetBookingVariables;

export type ReadSchedulePageCardsStatusOptions = FieldFunctionOptions<
  unknown,
  ReadSchedulePageCardsStatusVariables
>;

type ReadSchedulePageCardsStatus = (
  options: ReadSchedulePageCardsStatusOptions
) => SchedulePageCardsStatus | undefined;

/**
 * Infer schedule page cards status from booking data
 *
 * @todo why is this running on other checkout pages (eg. Quotes page)?
 */
export const readSchedulePageCardsStatus: ReadSchedulePageCardsStatus = ({ cache, variables }) => {
  const queryGetBooking = cache.readQuery<QueryGetBooking, QueryGetBookingVariables>({
    query: QUERY_GET_BOOKING,
    variables,
  });

  const booking = queryGetBooking?.getBooking;
  if (!booking) {
    return undefined;
  }

  const {
    collectionAddressId,
    collectionDateTimeLocal,
    deliveryDateTimeLocal,
    garageId,
    collectionDeliveryOption,
    segment,
    tags,
  } = booking;

  /**
   * @todo do we still need this flag or it's always true?
   */
  if (isFeatureSchedulePageOptionalCnDActive(segment)) {
    return determinePageStateCurrent(
      collectionAddressId,
      collectionDateTimeLocal,
      deliveryDateTimeLocal,
      garageId,
      collectionDeliveryOption as CollectionDeliveryTypes,
      getIsMembershipGarageFromBookingTags(tags)
    );
  }
  return determinePageStateDefault(
    collectionAddressId,
    collectionDateTimeLocal,
    deliveryDateTimeLocal,
    garageId,
    collectionDeliveryOption as CollectionDeliveryTypes
  );
};
