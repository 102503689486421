/**
 * Types for the GraphQL CMS Contentful response.
 *
 * @note the deprecated types relate to the old dictionary implementation
 */

import type { Document } from '@contentful/rich-text-types';

export enum CmsContentfulTypename {
  contentComponent = 'ContentComponent',
  shortText = 'ShortText',
  richText = 'ContentTypeRichText',
  longText = 'LongText',
  link = 'KbAppLink',
  image = 'Image',
  imageContent = 'ImageContent',
  singleImage = 'SingleImage',
  number = 'Number',

  navBar = 'NavBar',
  megaMenu = 'MegaMenu',

  googleReviewCarouselSection = 'GoogleReviewsCarouselSection',
  serviceCard = 'Product',
  carouselHeroSlide = 'CarouselHeroSlide',
  carouselHeroSection = 'CarouselHeroSection',

  blogCarouselSection = 'BlogCarousel',

  serviceCardsSection = 'ServiceCardsSection',
  productCard = 'ProductCard',
  productCardsSection = 'ProductCards',
  singleUSP = 'SingleUsp',
  uspSection = 'UspSection',
  footerSection = 'FooterSection',
  workshopDirectoryHeroSection = 'WorkshopDirectoryHeroSection',
  workshopHeroSection = 'WorkshopHeroSection',
  workshopContentSection = 'WorkshopContentSection',
  googleMapSection = 'GoogleMapSection',
  mobileStickyHeaderSection = 'MobileStickyHeaderSection',
  workshopsSection = 'WorkshopsSection',

  /**
   * @deprecated use `ContentfulContentComponent` related types
   */
  componentCollection = 'ComponentCollection',
  /**
   * @deprecated use `ContentfulContentComponent` related types
   */
  componentEntriesCollection = 'ComponentEntriesCollection',
  /**
   * @deprecated use `ContentfulContentComponent` related types
   */
  component = 'Component',
}

export interface ContentfulContentComponent {
  readonly __typename: CmsContentfulTypename.contentComponent;
  readonly id: string;
  readonly uiComponent: string;
  readonly translatedEntitiesCollection: {
    readonly items: ContentfulTranslatedEntity[];
  };
}

export interface CmsComponentCollection {
  readonly __typename: CmsContentfulTypename.componentCollection;
  readonly items: ContentfulContentComponent[];
}

export type ContentfulTranslatedEntity =
  | CmsContentfulShortText
  | CmsContentfulLongText
  | CmsContentfulRichText
  | CmsContentfulLink
  | CmsContentfulImage
  | CmsContentfulSingleImage
  | CmsContentfulNumber
  | CmsContentfulCarouselHeroSection
  | CmsContentfulProductCardSection
  | CmsContentfulGoogleReviewCarouselSection
  | CmsContentfulUSPSection
  | CmsContentfulServiceCardsSection
  | CmsContentfulFooterSection
  | CmsContentfulFooterSection
  | CmsContentfulBlogCarouselSection
  | CmsContentfulWorkshopDirectoryHeroSection
  | CmsContentfulWorkshopHeroSection
  | CmsContentfulWorkshopContentSection
  | CmsContentfulMegaMenu
  | CmsContentfulNavBar
  | CmsContentfulGoogleMapSection
  | CmsContentfulMobileStickyHeaderSection
  | CmsContentfulWorkshopsSection;

export interface CmsContentfulShortText {
  readonly __typename: CmsContentfulTypename.shortText;
  readonly key: string;
  readonly value: string;
}
export interface CmsContentfulNumber {
  readonly __typename: CmsContentfulTypename.number;
  readonly key: string;
  readonly number: number;
}

interface CmsContentfulRichTextContent {
  readonly json: Document;
}

export interface CmsContentfulRichText {
  readonly __typename: CmsContentfulTypename.richText;
  readonly key: string;
  readonly content: CmsContentfulRichTextContent;
}
export interface CmsContentfulLongText {
  readonly __typename: CmsContentfulTypename.longText;
  readonly key: string;
  readonly value: string;
}

export interface CmsContentfulLink {
  readonly __typename: CmsContentfulTypename.link;
  readonly key: string;
  readonly text: string;
  readonly url: string;
}

export interface CmsContentfulNativeImageData {
  readonly url: string;
  readonly description: string;
}

export interface CmsContentfulImageData {
  readonly original_secure_url: string;
  readonly width: number;
  readonly height: number;
  readonly url?: string;
  readonly tags?: string[];
  readonly type?: string;
  readonly bytes?: number;
  readonly format?: string;
  readonly version?: number;
  readonly duration?: string | null;
  readonly metadata?: Record<string, unknown>;
  readonly public_id?: string;
  readonly created_at?: string;
  readonly secure_url?: string;
  readonly original_url?: string;
  readonly resource_type?: string;
  readonly raw_transformation?: string;
}

export interface CmsContentfulImage {
  readonly __typename: CmsContentfulTypename.image;
  readonly key: string;
  readonly img: CmsContentfulImageData[];
  readonly alt: string;
  readonly title: string;
}

export interface CmsContentfulNativeImage {
  readonly __typename: CmsContentfulTypename.imageContent;
  readonly image: CmsContentfulNativeImageData;
}

export interface CmsContentfulSingleImage {
  readonly __typename: CmsContentfulTypename.singleImage;
  readonly key?: string;
  readonly alt: string;
  readonly image: CmsContentfulImageData[];
}

export interface CmsContentfulProductCard {
  readonly __typename: CmsContentfulTypename.productCard;
  readonly key: string;
  readonly heading: string;
  readonly subHeading: string;
  readonly image: CmsContentfulSingleImage;
  readonly ctaUrl: string;
  readonly ctaText: string;
}

export interface CmsContentfulProductCardSection {
  readonly __typename: CmsContentfulTypename.productCardsSection;
  readonly key: string;
  readonly title: string;
  readonly productCard1: CmsContentfulProductCard;
  readonly productCard2: CmsContentfulProductCard;
  readonly productCard3: CmsContentfulProductCard;
}

export interface CmsContentfulServiceCard {
  readonly __typename: CmsContentfulTypename.serviceCard;
  readonly key: string;
  readonly name: string;
  readonly slug: string;
  readonly price: string;
  readonly icon: CmsContentfulNativeImage;
  readonly termsConditions?: CmsContentfulRichTextContent;
}

export interface CmsContentfulServiceCardsSection {
  readonly __typename: CmsContentfulTypename.serviceCardsSection;
  readonly key: string;
  readonly title: string;
  readonly disclaimer?: string;
  readonly learnMoreText?: string;
  readonly learnMorePopupTitle?: string;
  readonly cta: CmsContentfulLink;
  readonly includingTaxText?: string;
  readonly serviceCardsCollection: {
    readonly items: CmsContentfulServiceCard[];
  };
}

export interface CmsContentfulGoogleReview {
  readonly key: string;
  readonly title: string;
  readonly stars: number;
  readonly name: string;
  readonly city: string;
  readonly reviewText: CmsContentfulRichTextContent;
}

export interface CmsContentfulGoogleReviewCarouselSection {
  readonly __typename: CmsContentfulTypename.googleReviewCarouselSection;
  readonly key: string;
  readonly title: string;
  readonly stars: number;
  readonly amongReviewsText: string;
  readonly googleReviewsCollection: {
    readonly items: CmsContentfulGoogleReview[];
  };
}

export interface CmsContentfulCarouselHeroSlide {
  __typename: CmsContentfulTypename.carouselHeroSlide;
  title: CmsContentfulRichTextContent;
  description: CmsContentfulRichTextContent;
  ctaLabel: string;
  ctaLink?: string | null;
  image: CmsContentfulNativeImage;
  tabletImage?: CmsContentfulNativeImage | null;
  mobileImage?: CmsContentfulNativeImage | null;
}

export interface CmsContentfulCarouselHeroSection {
  readonly __typename: CmsContentfulTypename.carouselHeroSection;
  readonly key: string;
  readonly mainSlide: CmsContentfulCarouselHeroSlide;
  readonly slidesCollection?: {
    items: CmsContentfulCarouselHeroSlide[];
  } | null;
  readonly googleReviewsText?: string | null;
  readonly googleReviewsStars?: number | null;
}

export interface CmsContentfulUSPSection {
  readonly __typename: CmsContentfulTypename.uspSection;
  readonly key: string;
  readonly title: string;
  readonly warrantyPartsImage: CmsContentfulSingleImage;
  readonly warrantyLabourImage: CmsContentfulSingleImage;
  readonly uspListCollection: {
    readonly items: CmsContentfulSingleUSP[];
  };
}

export interface CmsContentfulFooterSection {
  readonly __typename: CmsContentfulTypename.footerSection;
  readonly key: string;
  readonly logo: CmsContentfulSingleImage;

  readonly instagramLink: string;
  readonly facebookLink: string;
  readonly youtubeLink: string;
  readonly linkedInLink: string;

  readonly firstSectionTitle: string;
  readonly firstSectionLinksCollection: {
    readonly items: CmsContentfulLink[];
  };

  readonly secondSectionTitle: string;
  readonly secondSectionLinksCollection: {
    readonly items: CmsContentfulLink[];
  };

  readonly thirdSectionTitle: string;
  readonly thirdSectionLinksCollection: {
    readonly items: CmsContentfulLink[];
  };

  readonly copyright: string;
  readonly footerEndSectionCollection: {
    readonly items: CmsContentfulLink[];
  };
}

export interface CmsContentfulWorkshopDirectoryHeroSection {
  readonly __typename: CmsContentfulTypename.workshopDirectoryHeroSection;
  readonly key: string;
  readonly title: string;
  readonly secondaryTitle: string;
}

export interface CmsContentfulWorkshopHeroSection {
  readonly __typename: CmsContentfulTypename.workshopHeroSection;
  readonly key: string;
  readonly seeOtherGarages: string;
  readonly secondaryTitle: string;
  readonly secondaryTitleMotOnly: string;
  readonly secondaryTitleRepairsOnly: string;
  readonly amongReviewsText: string;
  readonly cta: CmsContentfulLink;
}

export interface CmsContentfulWorkshopContentSection {
  readonly __typename: CmsContentfulTypename.workshopContentSection;
  readonly key: string;
  readonly bookAnAppointment: CmsContentfulLink;
  readonly showMore: string;
  readonly showLess: string;
  readonly aboutUsSectionTitle: string;
  readonly ourServicesSectionTitle: string;
  readonly additionalServicesSectionTitle: string;
  readonly followUsOnSocialMedia: string;
}

export interface CmsContentfulGoogleMapSection {
  readonly __typename: CmsContentfulTypename.googleMapSection;
  readonly key: string;
  readonly postcodeInputPlaceholder: string;
  readonly expandMap: string;
  readonly hideMap: string;
}

export interface CmsContentfulMobileStickyHeaderSection {
  readonly __typename: CmsContentfulTypename.mobileStickyHeaderSection;
  readonly key: string;
  readonly title: string;
  readonly backToMap: string;
}

export interface CmsContentfulWorkshopsSection {
  readonly __typename: CmsContentfulTypename.workshopsSection;
  readonly key: string;
  readonly reviewQuantifierText: string;
  readonly reviewSectionTitle: string;
  readonly noWorkshopsInArea: string;
  readonly selectThisGarage: string;
  readonly seeAllGarages: string;
  readonly seeLessGarages: string;
  readonly seeMoreInfo: string;
  readonly seeLessInfo: string;
}

export interface ProcessedCmsContentfulImage {
  readonly __typename: CmsContentfulTypename.image;
  readonly key: string;
  readonly img: CmsContentfulImageData;
  readonly alt: string;
  readonly title: string;
}

export interface CmsContentfulBlogCarouselSection {
  readonly __typename: CmsContentfulTypename.blogCarouselSection;
  readonly key: string;
  readonly title: string;
  readonly seeMoreText: string;
}

export interface CmsContentfulNavBar {
  readonly __typename: CmsContentfulTypename.navBar;
  readonly key: string;
  readonly findAGarage: CmsContentfulLink;
  readonly bookAnAppointment: CmsContentfulLink;
  readonly menuText: string;
  readonly closeText: string;
  readonly backText: string;
}

export interface CmsContentfulMegaMenu {
  readonly __typename: CmsContentfulTypename.megaMenu;
  readonly key: string;
  readonly firstSectionTitle: string;
  readonly firstSectionLinksCollection: {
    items: CmsContentfulLink[];
  };
  readonly secondSectionTitle: string;
  readonly secondSectionLinksCollection: {
    items: CmsContentfulLink[];
  };
  readonly thirdSectionTitle: string;
  readonly thirdSectionLinksCollection: {
    items: CmsContentfulLink[];
  };
  readonly instagramLink: string;
  readonly facebookLink: string;
  readonly youtubeLink: string;
  readonly linkedInLink: string;
  readonly footerLinksCollection: {
    items: CmsContentfulLink[];
  };
}

export interface CmsContentfulCampaign {
  readonly label: string;
  readonly name: string;
  readonly offer: string;
  readonly primaryCta: CmsContentfulLink;
  readonly image?: CmsContentfulSingleImage;
  readonly mobileImage?: CmsContentfulSingleImage;
  readonly tabletImage?: CmsContentfulSingleImage;
  readonly seeMoreText: string;
  readonly seeMoreSlug: string;
  readonly tcsContent: CmsContentfulRichTextContent;
}

export interface CmsContentfulCampaignCollection {
  items: CmsContentfulCampaign[];
}

// Subtypes

export interface CmsContentfulSingleImageData {
  readonly url: string;
  readonly alt: string;
}
export interface CmsContentfulSingleUSP {
  readonly __typename: CmsContentfulTypename.singleUSP;
  readonly key: string;
  readonly title: string;
  readonly description: string;
}

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export type CmsContentfulContentData =
  | CmsContentfulShortText
  | CmsContentfulImageData
  | CmsContentfulRichText
  | CmsContentfulLongText
  | CmsContentfulImage
  | CmsContentfulLink;

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export type CmsContentfulComponentItem =
  | CmsContentfulShortText
  | CmsContentfulLongText
  | CmsContentfulImage
  | CmsContentfulRichText
  | CmsContentfulComponent
  | CmsContentfulLink
  | CmsContentfulNumber;

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export type CmsContentComponentTypes =
  | CmsContentfulShortText
  | CmsContentfulLongText
  | CmsContentfulImage
  | CmsContentfulRichText
  | CmsContentfulLink
  | CmsContentfulNumber;

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export interface CmsContentfulComponentCollection {
  readonly __typename: CmsContentfulTypename.componentCollection;
  readonly items: [CmsContentfulComponent];
}

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export interface CmsContentfulComponent {
  readonly __typename: CmsContentfulTypename.component;
  readonly name: string;
  readonly entriesCollection: CmsContentfulComponentEntriesCollection;
}

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export interface CmsContentfulComponentEntriesCollection {
  readonly __typename: CmsContentfulTypename.componentEntriesCollection;
  readonly items: CmsContentfulComponentItem[];
}

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export interface CmsContentfulComponentCollectionResponse {
  items: {
    __typename: CmsContentfulTypename.componentCollection;
    entriesCollection: {
      __typename: string;
      items: CmsContentfulComponentItem[];
    };
  };
}

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export interface CmsContentfulComponentResponse {
  component: {
    __typename: string;
    entriesCollection: {
      __typename: string;
      items: CmsContentfulComponentItem[];
    };
  };
}

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export interface CmsTranslatedEntitiesCollection {
  items: Omit<CmsContentfulComponentItem, 'CmsComponent'>;
}

/**
 * @deprecated use `ContentfulContentComponent` related types
 */
export interface CmsContentfulComponentsCollection {
  componentsCollection: {
    items: CmsTranslatedEntitiesCollection;
  };
}
